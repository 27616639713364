import * as React from "react"
import {

  Section,
  

} from "./ui"
import '../global.css'
import AniLink from "gatsby-plugin-transition-link/AniLink";


export default function Hero() {
  return (
    <Section className="background">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16" >
        <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
          <div className="flex flex-col justify-center">
            <div className="max-w-xl mb-6">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                </p>
              </div>
              <h1 className="H1 max-w-xl mb-6 lg:pt-20 font-Manrope font-bold tracking-tight text-white">
                Your Solution
                for<span class="herotxt pl-6 "></span><br></br></h1>
              <p className="text-base pt-4 text-white md:text-xl">
                IoT solutions & Software
                development.
              </p>
            </div>
            <AniLink
              fade to="/services"
              aria-label=""
              className="herobutton inline-flex items-center  max-w-sm  transition-colors text-white duration-200 hover:text-opacity-50 cursor-pointer"
            >
              Learn more
            </AniLink>
          </div>
          <div className="relative sm:pb-20">
          </div>
        </div>
      </div>
    </Section>
  )
}

