import * as React from "react"
import { graphql } from "gatsby"

export default function HomepageCta(props) {
  return (
    <div className="card px-3 py-12 mx-auto sm:max-w-xl rounded rounded-xl  lg:max-w-screen-xl md:px-8 lg:px-4 lg:py-10">
      <div className="max-w-xl sm:mx-auto lg:max-w-xl">
        <div className="flex flex-col mb-10 sm:text-center sm:mb-0">
          <div className="mb-6 sm:mx-auto"></div>
          <div className="max-w-2xl mb-10 md:mx-auto sm:max-w-md lg:max-w-xl md:mb-12">
            <h2 className="max-w-2xl mb-6 m-8  font-Manrope text-6xl font-bold text-white sm:text-4xl lg:text-5xl md:mx-auto">
              Ready to take your business to the next level?
            </h2>
            <p className="text-base text-white pl-6 sm:text-center sm:pl-6 md:text-lg">
              Contact us today to learn more about how we can help.
            </p>
          </div>
          <div className="sm:pl-6 pl-6">
            <a
              href="mailto:info@aikate.hr"
              className="contactbutton2 inline-flex  items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md hover:text-opacity-50 focus:shadow-outline focus:outline-none"
            >
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageCtaContent on HomepageCta {
    id
    kicker
    heading
    text
    image {
      alt
      id
      gatsbyImageData
    }
    links {
      id
      href
      text
    }
  }
`
